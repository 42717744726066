import React from 'react'
import {
  Home as HomeIcon,
  ManageAccount as ManageAccountIcon,
} from 'icons'
import { Routes, RouteDefinitions } from 'compositions/Routes'
import { Login } from './Login'
import { ResetPassword, ForgotPassword } from './ResetPassword'
import { Home } from './Home'
import { Profile } from './Profile'

export const routes: RouteDefinitions = [
  { path: '/', element: <Home />, name: 'Home', icon: HomeIcon, navMenu: false },
  { path: 'profile', element: <Profile />, name: 'Manage Account', icon: ManageAccountIcon, navMenu: false },
  { path: 'login', element: <Login />, name: 'Login', public: true },
  { path: 'users/password/new', element: <ForgotPassword />, name: 'Forgot Password', public: true, navMenu: false },
  { path: 'users/password/edit', element: <ResetPassword />, name: 'Reset Password', public: true, navMenu: false },
  { path: '*', element: <Login />, name: 'Login', public: true },
]

export const Router: React.FC = () => {
  return <Routes appName="Ordo Tools" routes={routes} />
}

export default Router
